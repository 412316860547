<template>
<footer class="footer">
  <div class="content has-text-centered">
    <a href="https://github.com/Glukoosi/ilmo.io" target="_blank">
    <span class="icon is-large">
      <img src="@/assets/logo-github.svg">
    </span>
    </a>
  </div>
</footer>
</template>

<script>
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
