<template>
  <section class="section">
    <div class="container content">
      <p class="is-size-1 has-text-centered has-text-weight-bold">Nothing here :(</p>
    </div>
  </section>
</template>

<script>
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>